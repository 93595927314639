export const ICON_SPRITE_URL = window.kesselblech.ICON_SPRITE_URL || '/media/icons/icons.svg';

export const LANG = document.documentElement.lang || 'de';

export const BREAKPOINTS = {
  s: '0px',
  m: '480px',
  l: '768px',
  xl: '990px',
  xxl: '1440px',
  navigation: '1250px',
};

export const MEDIA_QUERIES = {
  s: '(min-width: 0px)',
  m: '(min-width: 480px)',
  l: '(min-width: 768px)',
  xl: '(min-width: 1024px)',
};
