document.addEventListener('keydown', function(event) {
    if (event.ctrlKey && event.key === 'ü') {
        toggleVersion();
    }
});

function toggleVersion() {
    const div = document.getElementById("version");
    if (div.style.visibility === "hidden") {
        div.style.visibility = "visible";
    } else {
        div.style.visibility = "hidden";
    }
}