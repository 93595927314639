import { toDisplayString as _toDisplayString, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "resort-bubble" }
const _hoisted_2 = { class: "resort-bubble__text" }
const _hoisted_3 = {
  key: 0,
  class: "resort-bubble__member-indicator"
}

import { customError, customLog } from '../../../../javascripts/utils/log';
import { ref } from 'vue';
import ResortInfo from '../../resort-info/vue/resort-info.vue';


export default {
  __name: 'resort-bubble',
  props: {
  blockHover: {
    type: Boolean,
    required: false,
    default: false
  },
  showMemberIndicator: {
    type: Boolean,
    required: false,
    default: false
  },
  resortId: {
    type: String,
    required: true,
    validator: (resortId) => resortId.length > 0
  },
  resortName: {
    type: String,
    required: true,
    validator: (resortName) => resortName.length > 0
  },
  resortMemberCount: {
    type: Number,
    required: true,
  },
  resortInfoText: {
    type: String,
    validator: (resortInfoText) => resortInfoText.length > 0,
  }
},
  emits: {
  resortBubbleClicked: (resortId) => {
    if (resortId) {
      customLog(`[ResortBubble]: resortBubbleClicked event emitted with id ${resortId}`);
      return true;
    } else {
      customError('[ResortBubble]: resortBubbleClicked event must have an id');
      return false;
    }
  },
  resortInfoClicked: (resortId) => {
    if (resortId) {
      customLog(`[ResortBubble]: resortInfoClicked event emitted with id ${resortId}`);
      return true;
    } else {
      customError('[ResortBubble]: resortInfoClicked event must have an id');
      return false;
    }
  }
},
  setup(__props, { emit: __emit }) {

const props = __props;

const emit = __emit;

const resortInfoIsOpened = ref(false);

// Touch handling
const isMoving = ref(false);
const startX = ref(0);
const startY = ref(0);

function handleTouchStart(event) {
  startX.value = event.touches[0].clientX;
  startY.value = event.touches[0].clientY;
  isMoving.value = false;
}

function handleTouchMove() {
  isMoving.value = true;
}

function handleTouchEnd() {
  if (!isMoving.value) {
    handleClickResort();
  }
}

const handleClickResort = () => {
  customLog('[ResortBubble]: resort info has been clicked');
  if (resortInfoIsOpened.value) {
    customLog('[ResortBubble]: resort info is opened. resortBubbleClicked event will not be emitted');
    // do nothing
  } else {
    emit('resortBubbleClicked', props.resortId);
  }
}
const handleResortInfoOpened = () => {
  customLog('[ResortBubble]: received resortInfoOpened event');
  resortInfoIsOpened.value = true;
}
const handleResortInfoClosed = () => {
  customLog('[ResortBubble]: received resortInfoClosed event');
  resortInfoIsOpened.value = false;
}

return (_ctx, _cache) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: _normalizeClass(["resort-bubble__ring", __props.blockHover || resortInfoIsOpened.value ? '' : 'resort-bubble__ring--hover'])
    }, [
      _createElementVNode("div", {
        class: "resort-bubble__circle",
        onClick: handleClickResort,
        onTouchstart: handleTouchStart,
        onTouchmove: handleTouchMove,
        onTouchend: handleTouchEnd
      }, [
        _createElementVNode("div", _hoisted_2, [
          _createTextVNode(_toDisplayString(__props.resortName) + " ", 1),
          (__props.resortInfoText)
            ? (_openBlock(), _createBlock(ResortInfo, {
                key: 0,
                "show-info-text": false,
                onResortInfoOpened: handleResortInfoOpened,
                onResortInfoClosed: handleResortInfoClosed,
                "resort-info-text": __props.resortInfoText
              }, null, 8, ["resort-info-text"]))
            : _createCommentVNode("", true)
        ])
      ], 32)
    ], 2),
    (__props.showMemberIndicator)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createElementVNode("span", null, _toDisplayString(__props.resortMemberCount), 1)
        ]))
      : _createCommentVNode("", true)
  ]))
}
}

}