import { BREAKPOINTS } from 'javascripts/constants';
import { createFocusTrap } from 'focus-trap';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';

export default class NavigationToggle {
  constructor($toggleButton, options = {}) {
    this.options = {
      close: 'navigation__close',
      mainBlock: 'navigation__items',
      panelClass: 'navigation__item--level',
      innerClass: 'navigation__inner',
      ...options,
    };
    this.$toggleButton = $toggleButton;
    this.$panel = document.getElementById(this.$toggleButton.getAttribute('aria-controls'));
    this.$navInner = this.$panel.querySelector(`.${this.options.innerClass}`);
    this.$closeButton = this.$panel.querySelector(`.${this.options.close}`);
    this.$navigationMainBlock = this.$panel.querySelector(`.${this.options.mainBlock}`);
    this.mql = window.matchMedia(`(min-width: ${BREAKPOINTS.xl})`);

    // Binded events
    this.bindedClick = this.toggle.bind(this);
    this.bindedPanelClick = this.panelClick.bind(this);
    this.bindedClose = this.closePanel.bind(this);
    this.onKeydownBinded = this.onKeydown.bind(this);

    this.$toggleButton.addEventListener('click', this.bindedClick);
    this.$closeButton.addEventListener('click', this.bindedClose);
  }

  init() {
    // Init focus trap
    let focusTrapTarget = this.$panel;

    if (this.mql.matches) {
      focusTrapTarget = this.$panel.querySelector(`.${this.options.panelClass}`);
    }

    this.focusTrap = createFocusTrap(focusTrapTarget, {
      escapeDeactivates: false,
      clickOutsideDeactivates: true,
      returnFocusOnDeactivate: false,
    });
  }

  desktopCloseButton() {
    if (this.$navigationMainBlock) {
      const closeButton = this.$closeButton;
      this.$navigationMainBlock.parentNode.insertBefore(closeButton, this.$navigationMainBlock);
    }
  }

  toggle(event) {
    event.preventDefault();

    // Get current state of panel
    const isClosed = this.$toggleButton.getAttribute('aria-expanded') !== 'true';

    // Switch state
    if (isClosed) {
      this.openPanel();
    } else {
      this.closePanel();
    }
  }

  onKeydown(event) {
    // Close menu on ESC
    if (event.keyCode === 27) {
      event.preventDefault();
      this.closePanel();
    }
  }

  panelClick(event) {
    if (event.target.closest('.navigation')) {
      return;
    }

    this.closePanel();
  }

  closePanel() {
    document.body.classList.add('menu-close');
    document.body.classList.remove('menu-open');

    // Change toggle button
    this.$toggleButton.setAttribute('aria-expanded', 'false');

    // Close Search Panel
    this.$panel.classList.remove('header__navigation--open');
    this.$navInner.classList.remove('t-inverted', 't-dark-green', 't-green');

    // Remove keydown from body
    document.body.removeEventListener('keydown', this.onKeydownBinded);
    this.$panel.removeEventListener('click', this.bindedPanelClick);
    this.$closeButton.removeEventListener('click', this.bindedClose);

    // Disable focus trap
    if (this.focusTrap) {
      this.focusTrap.deactivate();
    }

    // Re-enable scrolling
    enableBodyScroll(this.$panel);
  }

  openPanel() {
    document.body.classList.add('menu-open');
    document.body.classList.remove('menu-close');

    // Change toggle button
    this.$toggleButton.setAttribute('aria-expanded', 'true');

    // Show search panel
    this.$panel.classList.add('header__navigation--open');
    this.$navInner.classList.add('t-inverted', 't-dark-green');

    // Add keydown to body
    document.body.addEventListener('keydown', this.onKeydownBinded);
    this.$panel.addEventListener('click', this.bindedPanelClick);
    this.$closeButton.addEventListener('click', this.bindedClose);

    // Disable scrolling

    if (/iPad|iPhone|iPod/.test(navigator.platform)
      // eslint-disable-next-line no-empty
      || (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1) === true) {
    } else {
      disableBodyScroll(this.$panel);
    }


    // Enable focus trap
    this.focusTrap.activate();
  }
}
