import Headroom from 'headroom.js';

// Headroom Js
const myElement = document.querySelector('header');
const headroom = new Headroom(myElement, {
  offset: 0,
  tolerance: {
    up: 20,
    down: 10,
  },
  classes: {
    initial: 'header--animated',
    pinned: 'header--slide-down',
    unpinned: 'header--slide-up',
    top: 'header--top',
    notTop: 'header--not-top',
  },
  onPin: () => {
    document.body.classList.add('headroom-header-pinned');
  },
  onUnpin: () => {
    document.body.classList.remove('headroom-header-pinned');
  },
});

document.querySelectorAll('.header').forEach(() => {
  headroom.init();
});
