import randomId from 'javascripts/utils/random-id';

async function initGoogleMap($map) {
  // The map container
  const $mapContainer = $map.querySelector('.google-map__map');
  // Marker links (links outside of google maps to control markers)
  const $markerLinks = $map.querySelectorAll('.google-map__marker-link');
  // Request needed libraries.
  const { Map } = await google.maps.importLibrary('maps');

  const {
    lat,
    lng,
    zoom,
    settings,
  } = $mapContainer.dataset;
  const mapSettingsPath = settings;
  const style = [
    {
      elementType: 'geometry',
      stylers: [
        {
          color: '#f5f5f5',
        },
      ],
    },
    {
      elementType: 'labels.icon',
      stylers: [
        {
          visibility: 'off',
        },
      ],
    },
    {
      elementType: 'labels.text.fill',
      stylers: [
        {
          color: '#616161',
        },
      ],
    },
    {
      elementType: 'labels.text.stroke',
      stylers: [
        {
          color: '#f5f5f5',
        },
      ],
    },
    {
      featureType: 'administrative.land_parcel',
      elementType: 'labels',
      stylers: [
        {
          visibility: 'off',
        },
      ],
    },
    {
      featureType: 'administrative.land_parcel',
      elementType: 'labels.text.fill',
      stylers: [
        {
          color: '#bdbdbd',
        },
      ],
    },
    {
      featureType: 'poi',
      elementType: 'geometry',
      stylers: [
        {
          color: '#eeeeee',
        },
      ],
    },
    {
      featureType: 'poi',
      elementType: 'labels.text',
      stylers: [
        {
          visibility: 'off',
        },
      ],
    },
    {
      featureType: 'poi',
      elementType: 'labels.text.fill',
      stylers: [
        {
          color: '#757575',
        },
      ],
    },
    {
      featureType: 'poi.park',
      elementType: 'geometry',
      stylers: [
        {
          color: '#e5e5e5',
        },
      ],
    },
    {
      featureType: 'poi.park',
      elementType: 'labels.text.fill',
      stylers: [
        {
          color: '#9e9e9e',
        },
      ],
    },
    {
      featureType: 'road',
      elementType: 'geometry',
      stylers: [
        {
          color: '#ffffff',
        },
      ],
    },
    {
      featureType: 'road.arterial',
      elementType: 'labels.text.fill',
      stylers: [
        {
          color: '#757575',
        },
      ],
    },
    {
      featureType: 'road.highway',
      elementType: 'geometry',
      stylers: [
        {
          color: '#dadada',
        },
      ],
    },
    {
      featureType: 'road.highway',
      elementType: 'labels.text.fill',
      stylers: [
        {
          color: '#616161',
        },
      ],
    },
    {
      featureType: 'road.local',
      elementType: 'labels',
      stylers: [
        {
          visibility: 'off',
        },
      ],
    },
    {
      featureType: 'road.local',
      elementType: 'labels.text.fill',
      stylers: [
        {
          color: '#9e9e9e',
        },
      ],
    },
    {
      featureType: 'transit.line',
      elementType: 'geometry',
      stylers: [
        {
          color: '#e5e5e5',
        },
      ],
    },
    {
      featureType: 'transit.station',
      elementType: 'geometry',
      stylers: [
        {
          color: '#eeeeee',
        },
      ],
    },
    {
      featureType: 'water',
      elementType: 'geometry',
      stylers: [
        {
          color: '#c9c9c9',
        },
      ],
    },
    {
      featureType: 'water',
      elementType: 'labels.text.fill',
      stylers: [
        {
          color: '#9e9e9e',
        },
      ],
    },
  ];
  // Create a list with the makers
  const mapMarkers = [];

  // If we do not have a settings path (json) get basic info from data-attributs
  if (!mapSettingsPath) {
    const mapLat = parseFloat(lat);
    const mapLng = parseFloat(lng);
    const mapZoom = parseFloat(zoom);

    const mapOptions = {
      center: {
        lat: mapLat,
        lng: mapLng,
      },
      styles: style,
      zoom: mapZoom,
    };

    // eslint-disable-next-line no-unused-vars
    const map = new Map($mapContainer, mapOptions);
  } else {
    // Fetch json as a source for the google maps settings
    fetch(mapSettingsPath)
      .then(response => response.json())
      .then((m) => {
        const mapOptions = m.options;
        mapOptions.styles = style;
        const map = new Map($mapContainer, mapOptions);
        const infowindow = new google.maps.InfoWindow();

        if (m.markers) {
          m.markers.forEach((marker) => {
            const markerId = marker.id || `marker-${randomId()}`;
            // An optional custom marker icon
            // eslint-disable-next-line no-undef-init
            let markerIcon = undefined;
            // The script assumes an icon on the same url, followed by the defined path
            const iconBase = `${window.location.protocol}//${window.location.host}`;
            if (m.icons && marker.icon) {
              markerIcon = iconBase + m.icons[marker.icon].icon;
            }

            // Create the marker
            const mapMarker = new google.maps.Marker({
              id: markerId,
              map,
              icon: markerIcon,
              position: marker.position,
              title: `${marker.title} ${markerId}`,
            });

            mapMarkers.push(mapMarker);

            // Add click handler on maker to open the infowindow
            if (infowindow && marker.content) {
              mapMarker.addListener('click', () => {
                infowindow.setContent(marker.content);
                infowindow.open({
                  anchor: mapMarker,
                  map,
                });
              });
            }
          });
        }
      });

    if ($markerLinks) {
      $markerLinks.forEach(($link) => {
        $link.addEventListener('click', () => {
          const targetId = $link.getAttribute('aria-controls');
          const target = mapMarkers.find(o => o.id === targetId);
          const targetLat = target.getPosition().lat();
          const targetLng = target.getPosition().lng();
          target.map.setCenter({ lat: targetLat, lng: targetLng });
          google.maps.event.trigger(target, 'click');
        });
      });
    }
  }
}


document.querySelectorAll('.js-google-map').forEach($map => initGoogleMap($map));
