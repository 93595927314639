import ApiClient from './api/fetching';
import ObjectConverter from './api/conversion';
import DataValidator from './api/validation';

import { customLog, customError } from '../../../../../../javascripts/utils/log';

class DataLoader {
  constructor(apiBaseUrl) {
    customLog('[DataLoader]: Initializing data loader');

    this.apiClient = new ApiClient(apiBaseUrl);
    this.objectConverter = new ObjectConverter();
    this.validator = new DataValidator();
    this.resortsLookupTable = {};
    this.personsLookupTable = {};
  }

  async getStaticTeamData() {
    customLog('[DataLoader]: Loading static team data');
    const jsonResponse = await this.apiClient.fetchStaticTeamData();
    const teamData = this.objectConverter.parseTeamDataFromResponse(jsonResponse);
    this.validator.validateTeamData(teamData);
    return teamData;
  }

  async getResortById(id) {
    customLog(`[DataLoader]: Looking up resort with id ${id}`);
    if (!this.resortsLookupTable[id]) {
      try {
        const fetchedResort = await this.apiClient.fetchResortById(id);
        const resortData = this.objectConverter.parseResortDataFromResponse(fetchedResort);
        this.addResort(resortData);
      } catch (error) {
        customError(`[DataLoader]: Error fetching resort with id ${id}:`, error);
        throw error;
      }
    }
    return this.resortsLookupTable[id];
  }

  async getPersonById(id) {
    customLog(`[DataLoader]: Looking up person with id ${id}`);
    if (!this.personsLookupTable[id]) {
      try {
        const fetchedPerson = await this.apiClient.fetchPersonById(id);
        const personData = this.objectConverter.parsePersonDataFromResponse(fetchedPerson);
        this.addPerson(personData);
      } catch (error) {
        customError(`[DataLoader]: Error fetching person with id ${id}:`, error);
        throw error;
      }
    }
    return this.personsLookupTable[id];
  }

  async getRelatedPersonsByResortId(resortId) {
    customLog(`[DataLoader]: Looking up related persons for resort with id ${resortId}`);
    try {
      const resort = await this.getResortById(resortId);
      const personPromises = resort.persons.map(person => this.getPersonById(person.id));
      return await Promise.all(personPromises);
    } catch (error) {
      customError(`[DataLoader]: Error fetching related persons for resort with id ${resortId}:`, error);
      throw error;
    }
  }

  async getRelatedResortsByPersonId(personId) {
    customLog(`[DataLoader]: Looking up related resorts for person with id ${personId}`);
    try {
      const person = await this.getPersonById(personId);
      const resortPromises = person.resorts.map(resort => this.getResortById(resort.id));
      return await Promise.all(resortPromises);
    } catch (error) {
      customError(`[DataLoader]: Error fetching related resorts for person with id ${personId}:`, error);
      throw error;
    }
  }

  addResort(resort) {
    if (resort) {
      customLog(`[DataLoader]: Adding resort with id ${resort.id} to lookup table`);
      this.resortsLookupTable[resort.id] = resort;
    }
  }

  addPerson(person) {
    if (person) {
      customLog(`[DataLoader]: Adding person with id ${person.id} to lookup table`);
      this.personsLookupTable[person.id] = person;
    }
  }
}


export default DataLoader;
