import { customLog } from '../../../../../../../javascripts/utils/log';

class ObjectConverter {
  parseTeamDataFromResponse(responseData) {
    customLog('[ObjectConverter]: parsing team data from response data');

    return {
      title: responseData.title,
      headline: responseData.headline,
      subline: responseData.subline,
      backButtonText: responseData.backButtonText,
      teamSelector: {
        message: responseData.selectionMenu.text,
        defaultOptionResort: responseData.selectionMenu.resortPlaceholderText,
        defaultOptionPerson: responseData.selectionMenu.personPlaceholderText,
        resorts: responseData.selectionMenu.resorts.map(resort => ({
          id: resort.id,
          name: resort.name,
          memberCount: resort.memberCount,
        })),
        persons: responseData.selectionMenu.persons.map(person => ({
          id: person.id,
          name: person.name,
          image: {
            thumbnail: person.image.thumbnail,
          },
        })),
      },
    };
  }

  parsePersonDataFromResponse(responseData) {
    customLog('[ObjectConverter]: parsing person data from response data');

    return {
      id: responseData.id,
      name: responseData.name,
      description: responseData.description,
      image: {
        src: responseData.image.src,
        alt: responseData.image.alt,
        thumbnail: responseData.image.thumbnail,
      },
      resorts: responseData.resorts.map(resort => ({
        id: resort.id,
        name: resort.name,
        memberCount: resort.memberCount,
      })),
      socials: responseData.socials.map((social) => {
        const [platform, link] = Object.entries(social)[0];
        return {
          icon: platform,
          link,
        };
      }),
      readMoreButton: {
        label: responseData.readMoreButton.label,
        link: responseData.readMoreButton.link,
      },
    };
  }

  parseResortDataFromResponse(responseData) {
    customLog('[ObjectConverter]: parsing resort data from response data');

    return {
      id: responseData.id,
      name: responseData.name,
      description: responseData.description,
      memberCount: responseData.memberCount,
      persons: responseData.persons.map(person => ({
        id: person.id,
        name: person.name,
      })),
    };
  }
}


export default ObjectConverter;
