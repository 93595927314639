// TODO Inputs brauchen fehlermeldung
// checkbox braucht fehlermeldung

const inputText = document.querySelectorAll('.input-text');
const select = document.querySelectorAll('.select');
const checkbox = document.querySelectorAll('.checkbox__input');
const radio = document.querySelectorAll('.radio__input');
const form = document.querySelectorAll('.form');


function checkInputText(input, errorSelector) {
  if (input.validity.valid) {
    errorSelector.classList.add('input-text--valid');
    errorSelector.classList.remove('input-text--error');
    return true;
  }
  errorSelector.classList.add('input-text--error');
  errorSelector.classList.remove('input-text--valid');
  return false;
}

// eslint-disable-next-line no-shadow
function checkSelect(select) {
  if (select.validity.valid) {
    select.removeAttribute('aria-invalid');
    return true;
  }
  select.setAttribute('aria-invalid', true);
  return false;
}

// eslint-disable-next-line no-shadow
function checkCheckbox(checkbox) {
  if (checkbox.validity.valid) {
    checkbox.removeAttribute('aria-invalid');
    return true;
  }
  checkbox.setAttribute('aria-invalid', true);
  return false;
}

// eslint-disable-next-line no-shadow
function checkRadio(radio) {
  if (radio.validity.valid) {
    radio.removeAttribute('aria-invalid');

    // eslint-disable-next-line max-len
    // entferne das aria-invalid Attribut von der gesamten Radio Gruppe wenn ein Radio der Gruppe valid ist
    const radioBoxName = radio.name;
    document.querySelectorAll('.radio__input').forEach((v) => {
      const $radio = v;
      if ($radio.name === radioBoxName) {
        v.removeAttribute('aria-invalid');
      }
    });

    return true;
  }
  radio.setAttribute('aria-invalid', true);
  return false;
}


// check input on change
inputText.forEach((v) => {
  const $input = v.querySelector('.input-text__input');

  $input.addEventListener('input', () => {
    checkInputText($input, v);
  });
});

// check select on change
select.forEach((v) => {
  const $select = v;

  $select.addEventListener('change', () => {
    checkSelect($select);
  });
});

// check Checkbox on change
checkbox.forEach((v) => {
  const $checkbox = v;

  $checkbox.addEventListener('change', () => {
    checkCheckbox($checkbox);
  });
});

// check radio on change
radio.forEach((v) => {
  const $radio = v;

  $radio.addEventListener('change', () => {
    checkRadio($radio);
  });
});

// check form
form.forEach((v) => {
  const $form = v;
  // eslint-disable-next-line no-unused-vars
  let selectValidCheck = true;
  let inputTextValidCheck = true;
  let checkboxValidCheck = true;
  let radioValidCheck = true;

  $form.addEventListener('submit', (event) => {
    // eslint-disable-next-line no-unused-vars
    selectValidCheck = true;
    inputTextValidCheck = true;
    checkboxValidCheck = true;
    radioValidCheck = true;


    // check input-text
    const FormInput = $form.querySelectorAll('.input-text');

    // eslint-disable-next-line no-shadow
    FormInput.forEach((v) => {
      const $input = v.querySelector('.input-text__input');

      checkInputText($input, v);

      if (checkInputText($input, v) === false) {
        inputTextValidCheck = false;
      }
    });

    // check select
    const formSelect = $form.querySelectorAll('.select');

    // eslint-disable-next-line no-shadow
    formSelect.forEach((v) => {
      checkSelect(v);

      if (checkSelect(v) === false) {
        selectValidCheck = false;
      }
    });

    // check checkBox
    const formCheckbox = $form.querySelectorAll('.checkbox__input');

    // eslint-disable-next-line no-shadow
    formCheckbox.forEach((v) => {
      checkCheckbox(v);

      if (checkCheckbox(v) === false) {
        checkboxValidCheck = false;
      }
    });

    // check radio
    const formRadio = $form.querySelectorAll('.radio__input');

    // eslint-disable-next-line no-shadow
    formRadio.forEach((v) => {
      // checkRadio(v);

      if (checkRadio(v) === false) {
        radioValidCheck = false;
      }
    });

    // submit or prevent check
    // eslint-disable-next-line max-len
    if (selectValidCheck === false || inputTextValidCheck === false || checkboxValidCheck === false || radioValidCheck === false) {
      event.preventDefault();
    } else {
      $form.submit();
    }
  });
});
