const breakerNumbers = document.querySelectorAll('.breaker-number__bubble');

Array.from(breakerNumbers).forEach((element) => {
  const $firstNumber = element.querySelector('.breaker-number__first-number');
  const firstNumber = $firstNumber.textContent;
  let percentage;

  if (element.querySelector('.breaker-number__second-number') != null) {
    const $secondNumber = element.querySelector('.breaker-number__second-number');
    const secondNumber = $secondNumber.textContent;
    percentage = 100 / secondNumber * firstNumber;
  } else {
    percentage = firstNumber;

    // add %
    $firstNumber.textContent = `${firstNumber}%`;
  }
  // eslint-disable-next-line no-param-reassign
  element.classList.add(`progress-${Math.round(percentage)}`);
});
