import initIframe from './iframe';


// eslint-disable-next-line import/prefer-default-export
export function videoOverlay() {
  document.querySelectorAll('.video__window[data-url]').forEach(async (element) => {
    const parent = element.closest('.video');
    // eslint-disable-next-line no-empty
    if (parent.classList.contains('video-loaded')) {
    } else {
      initIframe(element);
      parent.classList.add('video-loaded');
    }
  });


  document.querySelectorAll('.video__buttons .button').forEach((node) => {
    node.addEventListener('click', () => {
      if (node.dataset.cookieyt === 'always') {
        localStorage.setItem('always', true);
      }

      document.querySelectorAll('.video .video__window')
        .forEach($video => $video.classList.toggle('video__mod-hidden'));
      document.querySelectorAll('.video .video__overlay')
        .forEach($overlay => $overlay.classList.toggle('video__mod-hidden'));
    });
  });

  window.addEventListener('load', () => {
    if (localStorage.getItem('always') === 'true') {
      document.querySelectorAll('.video .video__window')
        .forEach($video => $video.classList.toggle('video__mod-hidden'));
      document.querySelectorAll('.video .video__overlay')
        .forEach($overlay => $overlay.classList.toggle('video__mod-hidden'));
    }
  });
}

videoOverlay();
