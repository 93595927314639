document.addEventListener('DOMContentLoaded', () => {
  const selectElements = document.querySelectorAll('.select--filter');
  if (selectElements) {
    selectElements.forEach((selectElement) => {
      selectElement.addEventListener('change', () => {
        const selectedValue = selectElement.value;
        if (selectedValue === 'reset') {
          // Set the selection to the first entry (index 0)
          // eslint-disable-next-line
          selectElement.selectedIndex = 0;
          if (selectElement.form) {
            // submit form on filter change
            selectElement.form.submit();
          }
        } else if (selectElement.form) {
          // submit form on filter change
          selectElement.form.submit();
        }
      });
    });
  }
});
