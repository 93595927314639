import { tns } from 'tiny-slider/src/tiny-slider';

document.addEventListener('DOMContentLoaded', () => {
  document.querySelectorAll('.slider--base').forEach((element) => {
    // eslint-disable-next-line no-unused-vars
    if (element.querySelector('.slider-base')) {
      // eslint-disable-next-line
      const slider = tns({
        container: element.querySelector('.slider-base'),
        items: 1,
        slideBy: 'page',
        autoplay: false,
        controlsPosition: 'bottom',
        navPosition: 'bottom',
        autoplayButtonOutput: false,
        controlsContainer: element.querySelector('.slider__controls'),
      });
    }
  });
});
