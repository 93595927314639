import h from 'hyperscript';
import getVideoId from 'get-video-id';

const initIframe = ($el) => {
  const url = $el.getAttribute('data-url');
  const { id, service } = getVideoId(url);

  let $iframeContent = null;

  if (id && service === 'youtube') {
    // Construct Youtube iframe player, add rel=0 to disable related videos
    $iframeContent = h('iframe.video__iframe', {
      src: `https://www.youtube-nocookie.com/embed/${id}?rel=0&autoplay=0`,
      allow: 'fullscreen; accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture',
    });
  } else if (id && service === 'vimeo') {
    // Construct Vimeo iframe player
    $iframeContent = h('iframe.video__iframe', {
      src: `https://player.vimeo.com/video/${id}`,
      allow: 'autoplay; fullscreen; picture-in-picture',
    });
  }

  if ($iframeContent) {
    $el.appendChild($iframeContent);
  }
};

export default initIframe;
