const copyIcon = document.getElementById('link');
const currentUrl = window.location.href;
const copyText = document.getElementById('copied');

if (copyIcon) {
  copyIcon.addEventListener('click', () => {
    navigator.clipboard.writeText(currentUrl);
    copyText.classList.remove('hidden');
    setTimeout(() => {
      copyText.classList.add('hidden');
    }, 1500);
  });
}
