const details = document.querySelectorAll('.accordion__item');

// Add the onclick listeners.
details.forEach((targetDetail) => {
  targetDetail.addEventListener('click', () => {
    // Close all the details that are not targetDetail.
    details.forEach((detail) => {
      if (detail !== targetDetail) {
        detail.removeAttribute('open');
      }
    });
  });
});
