// Import CustomSelect
// https://github.com/zoltantothcom/vanilla-js-dropdown
// eslint-disable-next-line import/no-extraneous-dependencies
import CustomSelect from 'vanilla-js-dropdown';

document.querySelectorAll('.select--custom').forEach((element) => {
  // eslint-disable-next-line no-new
  new CustomSelect({
    elem: element,
  });
});
