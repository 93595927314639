import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "team-selector__selection" }
const _hoisted_2 = { class: "team-selector__select" }
const _hoisted_3 = { class: "team-selector__select" }

import { ref } from 'vue';
import { customLog, customError } from '../../../../javascripts/utils/log';
import TeamDropdown from '../../team-dropdown/vue/team-dropdown.vue';


export default {
  __name: 'team-selector',
  props: {
  hasFullWidth: {
    type: Boolean,
    required: false,
    default: false,
  },
  message: {
    type: String,
    required: true,
    validator: (message) => message.length > 0,
  },
  resorts: {
    type: Array,
    required: true,
    validator: (resorts) => resorts.every(r => r.hasOwnProperty('id') && r.hasOwnProperty('name'))
  },
  persons: {
    type: Array,
    required: true,
    validator: (persons) => persons.every(p => p.hasOwnProperty('id') && p.hasOwnProperty('name'))
  },
  defaultOptionResort: {
    type: String,
    required: true,
    validator: (defaultOptionResort) => defaultOptionResort.length > 0,
  },
  defaultOptionPerson: {
    type: String,
    required: true,
    validator: (defaultOptionPerson) => defaultOptionPerson.length > 0,
  },
  initialResortId: {
    type: String,
    required: false,
    validator: (initialResortId) => initialResortId.length > 0,
    default: 'placeholder',
  },
  initialPersonId: {
    type: String,
    required: false,
    validator: (initialPersonId) => initialPersonId.length > 0,
    default: 'placeholder',
  },
},
  emits: {
  updateSelectedResort: (selectedId) => {
    if (!selectedId) {
      customLog('[TeamSelector]: emit updateSelectedResort event with null id');
      return false;
    }

    customLog('[TeamSelector]: emit updateSelectedResort event with id: ', selectedId);
    return true;
  },
  updateSelectedPerson: (selectedId) => {
    if (!selectedId) {
      customLog('[TeamSelector]: emit updateSelectedPerson event with null id');
      return false;
    }

    customLog('[TeamSelector]: emit updateSelectedPerson event with id: ', selectedId);
    return true;
  }
},
  setup(__props, { emit: __emit }) {

const props = __props;

const selectedResortId = ref(props.initialResortId);
const selectedPersonId = ref(props.initialPersonId);

const emit = __emit;

const handleResortSelection = (id) => {
  customLog('[TeamSelector]: handleResortSelection', id);
  if (selectedResortId.value === id) {
    customLog('[TeamSelector]: resort already selected');
    return;
  }
  if (!props.resorts.some(r => r.id === id)) {
    customError('[TeamSelector]: selectedResortId not found in resorts', id, props.resorts);
    throw new Error('selectedResortId not found in resorts', id, props.resorts);
  }
  if (selectedPersonId.value != 'placeholder') {
    customLog('[TeamSelector]: reset person selection');
    selectedPersonId.value = 'placeholder'; // <- reset the person selection
  }
  selectedResortId.value = id;
  emit('updateSelectedResort', id);
};

const handlePersonSelection = (id) => {
  customLog('[TeamSelector]: handlePersonSelection', id);
  if (selectedPersonId.value === id) {
    customLog('[TeamSelector]: person already selected');
    return;
  }
  if (!props.persons.some(p => p.id === id)) {
    customError('[TeamSelector]: selectedPersonId not found in persons', id, props.persons);
    throw new Error('selectedPersonId not found in persons', id, props.persons);
  }
  if (selectedResortId.value != 'placeholder') {
    customLog('[TeamSelector]: reset resort selection');
    selectedResortId.value = 'placeholder'; // <- reset the resort selection
  }
  selectedPersonId.value = id;
  emit('updateSelectedPerson', id);
};

const teamMenuIsOpen = ref(false);
const personMenuIsOpen = ref(false);

const handleToggleState = (payload) => {
  customLog('[TeamSelector]: handleToggleState', payload);
  if (payload.id === 'resort') {
    teamMenuIsOpen.value = payload.isOpen;
    personMenuIsOpen.value = false;
  }
  if (payload.id === 'person') {
    personMenuIsOpen.value = payload.isOpen;
    teamMenuIsOpen.value = false;
  }
};

return (_ctx, _cache) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["team-selector", { 'team-selector--full-width': __props.hasFullWidth }])
  }, [
    _createElementVNode("div", {
      class: _normalizeClass(["team-selector__inner", { 'team-selector__inner--full-width': __props.hasFullWidth }])
    }, [
      (__props.message)
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            class: _normalizeClass(["team-selector__message", { 'team-selector__message--full-width': __props.hasFullWidth }])
          }, _toDisplayString(__props.message), 3))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(TeamDropdown, {
            identifier: "resort",
            "is-open": teamMenuIsOpen.value,
            onUpdateToggleState: handleToggleState,
            items: __props.resorts,
            placeholder: __props.defaultOptionResort,
            "selected-id": selectedResortId.value,
            onUpdateSelection: handleResortSelection,
            label: "Team Selection Menu"
          }, null, 8, ["is-open", "items", "placeholder", "selected-id"])
        ]),
        _createElementVNode("div", _hoisted_3, [
          _createVNode(TeamDropdown, {
            identifier: "person",
            "is-open": personMenuIsOpen.value,
            onUpdateToggleState: handleToggleState,
            items: __props.persons,
            placeholder: __props.defaultOptionPerson,
            "selected-id": selectedPersonId.value,
            onUpdateSelection: handlePersonSelection,
            label: "Person Selection Menu"
          }, null, 8, ["is-open", "items", "placeholder", "selected-id"])
        ])
      ])
    ], 2)
  ], 2))
}
}

}