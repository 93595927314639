import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, withModifiers as _withModifiers, normalizeClass as _normalizeClass, vShow as _vShow, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  id: "team-dropdown__label",
  class: "team-dropdown__label sr-only"
}
const _hoisted_2 = { class: "select" }
const _hoisted_3 = {
  class: "team-dropdown__selected",
  "aria-labelledby": "team-dropdown__label"
}
const _hoisted_4 = { class: "team-dropdown__scrollable" }
const _hoisted_5 = {
  class: "team-dropdown__options",
  role: "listbox",
  tabindex: "-1",
  "aria-labelledby": "team-dropdown__label"
}
const _hoisted_6 = ["aria-selected", "onClick"]

import { computed, ref, onMounted, onUnmounted, watch } from 'vue';
import { customError, customLog } from '../../../../javascripts/utils/log';


export default {
  __name: 'team-dropdown',
  props: {
  identifier: {
    type: String,
    required: true
  },
  isOpen: {
    type: Boolean,
    default: false
  },
  label: {
    type: String,
    default: 'Dropdown Menu'
  },
  items: {
    type: Array,
    required: true
  },
  placeholder: {
    type: String,
    default: ''
  },
  selectedId: {
    type: String,
  }
},
  emits: {
  updateSelection: (selectedId) => {
    if (!selectedId) {
      customLog('[CustomDropdown]: emit updateSelection event with null id');
      return false;
    }

    customLog('[CustomDropdown]: emit updateSelection event with id: ', selectedId);
    return true;
  }
},
  setup(__props, { emit: __emit }) {

const props = __props;

const emit = __emit;

const internalIsOpen = ref(props.isOpen);
watch(() => props.isOpen, (newVal) => {
  internalIsOpen.value = newVal;
});

const selectedItem = computed(() => {
  customLog('[CustomDropdown]: computed selectedItem: ', props.selectedId);
  if (!props.selectedId || props.selectedId === 'placeholder') {
    return {
      id: 'placeholder',
      name: props.placeholder
    }
  }
  if (!props.items.some(i => i.id === props.selectedId)) {
    customError('[CustomDropdown]: selectedId not found in items', props.selectedId, props.items);
    throw new Error('selectedId not found in items', props.selectedId, props.items);
  }
  return props.items.find(i => i.id === props.selectedId);
})

const toggleDropdown = () => {
  customLog('[CustomDropdown]: toggle dropdown');
  internalIsOpen.value = !internalIsOpen.value;
  emit('updateToggleState', {
    id: props.identifier,
    isOpen: internalIsOpen.value
  });
};

const handleSelection = (item) => {
  customLog('[CustomDropdown]: selected item: ', item);
  if (selectedItem.value && item.id === selectedItem.value.id) {
    customLog('[CustomDropdown]: item already selected');
    internalIsOpen.value = false;
    return;
  }
  internalIsOpen.value = false;
  emit('updateSelection', item.id);
};

// Start: close dropdown when clicking outside
const closeDropdown = () => {
  if (internalIsOpen.value) {
    internalIsOpen.value = false;
    emit('updateToggleState', {
      id: props.identifier,
      isOpen: internalIsOpen.value
    });
  }
};
const clickOutsideEvent = (event) => {
  const dropdownElement = document.querySelector('.team-dropdown');
  if (!(dropdownElement.contains(event.target))) {
    closeDropdown();
  }
};
onMounted(() => {
  document.addEventListener('click', clickOutsideEvent);
  // document.addEventListener('touchstart', clickOutsideEvent);
});
onUnmounted(() => {
  document.removeEventListener('click', clickOutsideEvent);
  //document.removeEventListener('touchstart', clickOutsideEvent);
});
// End: close dropdown when clicking outside

return (_ctx, _cache) => {
  return (selectedItem.value)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: "team-dropdown",
        onClick: _withModifiers(toggleDropdown, ["stop"]),
        role: "combobox",
        "aria-haspopup": "listbox",
        "aria-expanded": "internalIsOpen",
        tabindex: "0"
      }, [
        _createElementVNode("label", _hoisted_1, _toDisplayString(__props.label), 1),
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, _toDisplayString(selectedItem.value.name), 1)
        ]),
        _withDirectives(_createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.items, (item) => {
              return (_openBlock(), _createElementBlock("div", {
                key: item.id,
                class: _normalizeClass(["team-dropdown__option", { 'team-dropdown__option--selected': item.id === selectedItem.value.id }]),
                role: "option",
                "aria-selected": item.id === selectedItem.value.id,
                onClick: _withModifiers($event => (handleSelection(item)), ["stop"]),
                tabindex: "0"
              }, _toDisplayString(item.name), 11, _hoisted_6))
            }), 128))
          ])
        ], 512), [
          [_vShow, internalIsOpen.value]
        ])
      ]))
    : _createCommentVNode("", true)
}
}

}