import { customError, customLog } from '../../../../../../../javascripts/utils/log';

class DataValidator {
  validateTeamData = (data) => {
    customLog('[DataValidator]: validating team data');
    if (!data.title) {
      customError('[DataValidator]: Missing title');
      throw new Error('Missing title');
    }
    if (!data.headline) {
      customError('[DataValidator]: Missing headline');
      throw new Error('Missing headline');
    }
    if (!data.subline) {
      customError('[DataValidator]: Missing subline');
      throw new Error('Missing subline');
    }
    if (!data.backButtonText) {
      customError('[DataValidator]: Missing backButtonText');
      throw new Error('Missing backButtonText');
    }
    if (!data.teamSelector.message) {
      customError('[DataValidator]: Missing teamSelector.message');
      throw new Error('Missing teamSelector.message');
    }
    if (!data.teamSelector.defaultOptionResort) {
      customError('[DataValidator]: Missing teamSelector.defaultOptionResort');
      throw new Error('Missing teamSelector.defaultOptionResort');
    }
    if (!data.teamSelector.defaultOptionPerson) {
      customError('[DataValidator]: Missing teamSelector.defaultOptionPerson');
      throw new Error('Missing teamSelector.defaultOptionPerson');
    }
    if (!data.teamSelector.resorts) {
      customError('[DataValidator]: Missing teamSelector.resorts');
      throw new Error('Missing teamSelector.resorts');
    }
    if (!data.teamSelector.persons) {
      customError('[DataValidator]: Missing teamSelector.persons');
      throw new Error('Missing teamSelector.persons');
    }
    data.teamSelector.resorts.forEach((resort) => {
      if (!resort.id) {
        customError('[DataValidator]: Missing resort id');
        throw new Error('Missing resort id');
      }
      if (!resort.name) {
        customError('[DataValidator]: Missing resort name');
        throw new Error('Missing resort name');
      }
    });
    data.teamSelector.persons.forEach((person) => {
      if (!person.id) {
        customError('[DataValidator]: Missing person id');
        throw new Error('Missing person id');
      }
      if (!person.name) {
        customError('[DataValidator]: Missing person name');
        throw new Error('Missing person name');
      }
    });
  };
}


export default DataValidator;
