import { customError, customLog } from '../../../../../../../javascripts/utils/log';

class ApiClient {
  constructor(apiBaseUrl) {
    this.apiBaseUrl = apiBaseUrl;
    this.cacheName = 'team-cache';
    this.cacheTimeInMinutes = 30;
  }

  async openCache() {
    return caches.open(this.cacheName);
  }

  async fetchWithCache(url, cacheKey) {
    const cache = await this.openCache();
    const cachedResponse = await cache.match(cacheKey);
    if (cachedResponse) {
      const timeCached = new Date(cachedResponse.headers.get('date'));
      if (Date.now() <= (timeCached.getTime() + 1000 * 60 * this.cacheTimeInMinutes)) {
        customLog(`[ApiClient]: Returning cached data for ${cacheKey}`);
        return cachedResponse.json();
      }
    }

    try {
      const response = await fetch(url);
      // Clone the response as the response stream can only be read once.
      const responseToCache = response.clone();
      cache.put(cacheKey, responseToCache);
      customLog(`[ApiClient]: Data fetched and cached for ${cacheKey}`);
      return response.json();
    } catch (error) {
      customError(`[ApiClient]: Error fetching data from ${url}:`, error);
      throw error;
    }
  }

  async fetchStaticTeamData() {
    const url = `${this.apiBaseUrl}/api/teams/create`;
    return this.fetchWithCache(url, url);
  }

  async fetchResortById(resortId) {
    const url = `${this.apiBaseUrl}/api/teams/resort/${resortId}`;
    return this.fetchWithCache(url, url);
  }

  async fetchPersonById(personId) {
    const url = `${this.apiBaseUrl}/api/teams/person/${personId}`;
    return this.fetchWithCache(url, url);
  }
}

export default ApiClient;
