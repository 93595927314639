import { resolveDynamicComponent as _resolveDynamicComponent, toHandlerKey as _toHandlerKey, mergeProps as _mergeProps, openBlock as _openBlock, createBlock as _createBlock, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = {
  class: "team-presenter-panzoom",
  id: "team-presenter-panzoom"
}
const _hoisted_2 = { class: "team-presenter" }
const _hoisted_3 = ["viewBox"]
const _hoisted_4 = ["d"]

import { computed, onMounted } from 'vue';
import Panzoom from 'panzoom';
import { customError, customLog } from '../../../../../../javascripts/utils/log';

const radius = 250;
const angleOffsetDegrees = 40;

const svgSize = 400;


export default {
  __name: 'team-presenter',
  props: {
  innerComponent: {
    type: Object,
    required: true,
  },
  innerComponentClickEventName: {
    type: String,
    required: true,
  },
  innerComponentProps: {
    type: Object,
    required: true,
  },
  outerComponent: {
    type: Object,
    required: true,
  },
  outerComponentClickEventName: {
    type: String,
    required: true,
  },
  outerComponentsProps: {
    type: Array,
    required: true,
  },
},
  emits: {
  innerComponentClicked: (id) => {
    if (id) {
      customLog(`[TeamPresenter]: innerComponentClicked event emitted with id ${id}`);
      return true;
    } else {
      customError('[TeamPresenter]: innerComponentClicked event must have an id');
      return false;
    }
  },
  outerComponentClicked: (id) => {
    if (id) {
      customLog(`[TeamPresenter]: outerComponentClicked event emitted with id ${id}`);
      return true;
    } else {
      customError('[TeamPresenter]: outerComponentClicked event must have an id');
      return false;
    }
  }
},
  setup(__props, { emit: __emit }) {

const props = __props;

const emit = __emit;

// outer component positioning
function calculatePosition(index, total) {
  // if total is 2 or less, we want to place the components somewhere between 180 and 360 degrees
  // if total is 3 or more, we want to place the components equally spaced between 0 and 360 degrees
  let radiant = 2 * Math.PI;

  if (total <= 2) {
    radiant = radiant / 2;
  }

  const angle = (radiant / total) * index + ((angleOffsetDegrees + 180) * Math.PI / 180);
  const x = radius * Math.cos(angle);
  const y = radius * Math.sin(angle);

  return {
    position: 'absolute',
    transform: `translate(${x}px, ${y}px)`
  };
}

// svg lines
const lines = computed(() => {
  return props.outerComponentsProps.map((_, index) => {
    let radiant = 2 * Math.PI;

    if (props.outerComponentsProps.length <= 2) {
      radiant = radiant / 2;
    }

    const angle = (radiant / props.outerComponentsProps.length) * index + ((angleOffsetDegrees + 180) * Math.PI / 180);
    const x = radius * Math.cos(angle);
    const y = radius * Math.sin(angle);
    return {
      d: `M 0 0 L ${x} ${y}`
    };
  });
});

// zooming
let panzoomInstance = null;

onMounted(() => {
  customLog('[TeamPresenter]: onMounted hook');
  const panzoomElement = document.getElementById('team-presenter-panzoom');
  panzoomInstance = Panzoom(panzoomElement, {
    initialZoom: 0.8,
    minZoom: 0.5,
    maxZoom: 2,
    bounds: true,
    boundsPadding: 0.5,
    // autocenter: true, <- option to disable zoom and scroll
  });
  const centerX = (panzoomElement.clientWidth * (1 - 0.8)) / 2;
  const centerY = (panzoomElement.clientHeight * (1 - 0.8)) / 2;
  panzoomInstance.moveTo(centerX, centerY);

  // For mousewheel zooming
  panzoomElement.addEventListener('wheel', panzoomInstance.zoomWithWheel);
});

return (_ctx, _cache) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(), _createBlock(_resolveDynamicComponent(__props.innerComponent), _mergeProps({ class: "team-presenter__inner-component" }, __props.innerComponentProps, {
        [_toHandlerKey(__props.innerComponentClickEventName)]: _cache[0] || (_cache[0] = $event => (_ctx.$emit('innerComponentClicked', $event)))
      }), null, 16)),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.outerComponentsProps, (outerComponentProps, index) => {
        return (_openBlock(), _createElementBlock("div", {
          key: index,
          style: _normalizeStyle(calculatePosition(index, __props.outerComponentsProps.length)),
          class: "team-presenter__outer-component"
        }, [
          (_openBlock(), _createBlock(_resolveDynamicComponent(__props.outerComponent), _mergeProps(outerComponentProps, {
            [_toHandlerKey(__props.outerComponentClickEventName)]: _cache[1] || (_cache[1] = $event => (_ctx.$emit('outerComponentClicked', $event)))
          }), null, 16))
        ], 4))
      }), 128)),
      (_openBlock(), _createElementBlock("svg", {
        class: "team-presenter__lines",
        viewBox: `-${svgSize / 2} -${svgSize / 2} ${svgSize} ${svgSize}`
      }, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(lines.value, (line) => {
          return (_openBlock(), _createElementBlock("path", {
            d: line.d
          }, null, 8, _hoisted_4))
        }), 256))
      ], 8, _hoisted_3))
    ])
  ]))
}
}

}