import { createFocusTrap } from 'focus-trap';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';

export default class NavigationSearch {
  constructor($el) {
    this.$toggle = $el;
    this.$panel = this.$toggle.parentNode;
    this.$toggleTarget = document.getElementById(`${this.$toggle.getAttribute('aria-controls')}`);
    this.openModifierClass = 'navigation__search--open';
    this.openModifierPanelClass = 'navigation__item--search-open';

    this.init();
  }

  init() {
    const toggleButtonClickBinded = this.toggleButtonClick.bind(this);
    this.$toggle.addEventListener('click', toggleButtonClickBinded);
    this.bindedOutsideClick = this.outsideClick.bind(this);
    this.onKeydownBinded = this.onKeydown.bind(this);

    // Init focus trap
    this.focusTrap = createFocusTrap(this.$panel, {
      escapeDeactivates: false,
      clickOutsideDeactivates: true,
    });

    this.close();
  }

  onKeydown(event) {
    // Close menu on ESC
    if (event.keyCode === 27) {
      event.preventDefault();
      this.close();
    }
  }

  outsideClick(event) {
    if (event.target.closest('.navigation__search') || event.target.closest('.navigation__search-toggle')) return;

    this.close();
  }


  toggleButtonClick() {
    const expanded = this.$toggle.getAttribute('aria-expanded');

    if (expanded === 'true') {
      this.close();
    } else {
      this.open();
    }
  }

  close() {
    this.$toggle.setAttribute('aria-expanded', 'false');
    this.$toggleTarget.classList.remove(this.openModifierClass);
    this.$panel.classList.remove(this.openModifierPanelClass);
    document.body.removeEventListener('click', this.bindedOutsideClick);
    document.body.removeEventListener('keydown', this.onKeydownBinded);

    // Disable focus trap
    if (this.focusTrap) {
      this.focusTrap.deactivate();
    }

    // Re-enable scrolling
    enableBodyScroll(this.$panel);
  }

  open() {
    const $searchInput = this.$toggleTarget.querySelector('input[type="search"]');

    this.$toggle.setAttribute('aria-expanded', 'true');
    this.$toggleTarget.classList.add(this.openModifierClass);
    this.$panel.classList.add(this.openModifierPanelClass);
    document.body.addEventListener('click', this.bindedOutsideClick);
    document.body.addEventListener('keydown', this.onKeydownBinded);
    $searchInput.focus();

    // Enable focus trap
    this.focusTrap.activate();

    // Disable scrolling
    disableBodyScroll(this.$panel);
  }
}
