import { tns } from 'tiny-slider/src/tiny-slider';

/* document.addEventListener('DOMContentLoaded', () => {
  document.querySelectorAll('.teaser-logo-slider__wrapper').forEach(
    $slideshow => tns({
      container: $slideshow,
      items: 1,
      slideBy: 'page',
      autoplay: false,
      controlsPosition: 'bottom',
      navPosition: 'bottom',
      autoplayButtonOutput: false,
      controlsContainer: '.teaser-logo-slider__controls',
      responsive: {
        768: {
          items: 3,
        },
      },
    }),
  );
}); */

document.addEventListener('DOMContentLoaded', () => {
  document.querySelectorAll('.teaser-logo-slider').forEach(async (element) => {
    // eslint-disable-next-line no-unused-vars
    const slider = tns({
      container: element.querySelector('.teaser-logo-slider__wrapper'),
      items: 1,
      slideBy: 'page',
      autoplay: false,
      controlsPosition: 'bottom',
      navPosition: 'bottom',
      autoplayButtonOutput: false,
      controlsContainer: element.querySelector('.teaser-logo-slider__controls'),
      responsive: {
        768: {
          items: 3,
        },
      },
    });
  });
});
