const scrollyHighlight = [...document.querySelectorAll('.scrolly-highlight')];
const stickySections = [...document.querySelectorAll('.scrolly-highlight__scroll-container')];
const scrollyHighlightResizeDelay = 300;
var scrollyHighlightResizeInterval = undefined;

function ScrollyHighlightBinder() {
  // TODO nur ausführen wenn der viewport größer als 768 ist oder das element die klasse -video hat
  if (window.visualViewport.width >= 768) {
    window.addEventListener('scroll',
      () => {
        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < stickySections.length; i++) {
          // eslint-disable-next-line no-use-before-define
          transform(stickySections[i]);

          const stickySectionItems = stickySections[i].querySelectorAll('.scrolly-highlight__item');

          // eslint-disable-next-line no-use-before-define
          // eslint-disable-next-line no-plusplus
          for (let elements = 0; elements < stickySectionItems.length; elements++) {
            if (typeof stickySectionItems[elements].querySelector('.figure') !== 'undefined') {
              // eslint-disable-next-line no-use-before-define
              paralaxeImage(stickySectionItems[elements]);
            }
          }
        }
      });


    // video variante - positionieren von .scrolly-highlight__blending-helper
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < stickySections.length; i++) {
      if (stickySections[i].parentElement.classList.contains('scrolly-highlight--video')) {
        const scrollItems = stickySections[i].querySelectorAll('.scrolly-highlight__item');
        let scrollItemWidth = 0;

        scrollItems.forEach((v) => {
          scrollItemWidth += v.offsetWidth;
        });

        stickySections[i].parentElement.querySelector('.scrolly-highlight__blending-helper').style.left = `${scrollItemWidth - 1}px`;
      }
    }

    // set scrolly height
    scrollyHighlight.forEach((i) => {
      let scrollItemWidth = 0;

      i.querySelectorAll('.scrolly-highlight__item').forEach((v) => {
        scrollItemWidth += v.offsetWidth;
      });

      // eslint-disable-next-line no-param-reassign
      i.style.height = `${scrollItemWidth}px`;
    });
  }

  // Mobile Animation für .scrolly-highlight--video
  if (window.visualViewport.width < 768) {
    scrollyHighlight.forEach((element) => {
      if (element.classList.contains('scrolly-highlight--video')) {
        const $headline = element.querySelector('.scrolly-highlight__headline');
        const headlineWidth = $headline.clientWidth;
        const viewportWidth = window.visualViewport.width;
        const animationTime = element.dataset.mobileanimationtime;
        let userScrolled = false;

        // eslint-disable-next-line func-names
        window.onscroll = function () {
          userScrolled = true;
        };

        $headline.style.transition = `left ${animationTime}ms ease-in`;
        $headline.style.left = `${(headlineWidth - viewportWidth) * -1}px`;

        // setTimeout(() => {
        //   if (userScrolled === false) {
        //     window.scrollTo(0, 400);
        //   }
        //   // eslint-disable-next-line radix
        // }, parseInt(animationTime) + 500);
      }
    });
  }


  function paralaxeImage(element) {
    if (typeof element.querySelector('.figure') !== 'undefined') {
      const maxImageLeftPosition = 600;
      const bounding = element.getBoundingClientRect();
      let percentage = 100 / 500 * (bounding.left * -1);

      if (percentage < 0) {
        percentage = 0;
      } else if (percentage > 100) {
        percentage = 100;
      }

      const imagePosition = maxImageLeftPosition / 100 * percentage;

      // eslint-disable-next-line valid-typeof
      if (element.querySelector('.teaser-highlight__image') !== null) {
        const $image = element.querySelector('.teaser-highlight__image');

        if (percentage >= 0 && percentage <= 100) {
          $image.style.left = `${imagePosition}px`;
        }
      }
    }
  }

  function transform(section) {
    const { offsetTop } = section.parentElement;
    const scrollSection = section.querySelector('.scrolly-highlight__scroll-container-sub');
    const scrollItems = section.querySelectorAll('.scrolly-highlight__item');
    let scrollItemWidth = 0;

    scrollItems.forEach((v) => {
      scrollItemWidth += v.offsetWidth;
    });

    let percentage = ((window.scrollY - offsetTop));
    // eslint-disable-next-line no-nested-ternary
    percentage = percentage < 0 ? 0 : percentage > scrollItemWidth ? scrollItemWidth : percentage;

    scrollSection.style.transform = `translate3d(${-(percentage)}px,0,0)`;

    // video variante
    if (section.parentElement.classList.contains('scrolly-highlight--video')) {
      // eslint-disable-next-line no-param-reassign
      section.querySelector('.scrolly-highlight__video').style.left = `${percentage}px`;
    }
  }
}

window.addEventListener('load', () => {
  ScrollyHighlightBinder();

  window.addEventListener('resize',(e)=>{
    window.clearInterval(scrollyHighlightResizeInterval);
    scrollyHighlightResizeInterval = window.setInterval(()=>{
      window.clearInterval(scrollyHighlightResizeInterval);
      ScrollyHighlightBinder();
    },scrollyHighlightResizeDelay);
  });
});
