window.addEventListener('load', () => {
    const isMobile = window.matchMedia("(max-width: 767px)").matches;

    if (isMobile) {
        /*const slider = document.querySelector('.teaser-logo-slider__wrapper');*/

        const flipCards = document.querySelectorAll('.teaser_logo__card');
        const flipButtons = document.querySelectorAll('.teaser_logo__flip-button');
        const flipBackButtons = document.querySelectorAll('.teaser_logo__flip-back-button');

        flipCards.forEach((flipCard) => {
            flipCard.addEventListener('touchmove', function(event) {
                event.stopImmediatePropagation();
            }, true)
            flipCard.addEventListener('touchstart', function(event) {
                event.stopImmediatePropagation();
            }, true)
        })

        flipButtons.forEach(button => {
            button.addEventListener('click', () => {
                const card = button.closest('.teaser_logo__card');
                card.classList.add('is-flipped');

                const flipBackButton = card.querySelector('.teaser_logo__flip-back-button');
                const flipButton = card.querySelector('.teaser_logo__flip-button');
                if (card.classList.contains('is-flipped')) {
                    flipBackButton.style.display = 'block';
                    flipButton.style.display = 'none';
                }
            });
        });

        flipBackButtons.forEach(button => {
            button.addEventListener('click', () => {
                const card = button.closest('.teaser_logo__card');
                card.classList.remove('is-flipped');
                button.style.display = 'none';

                const flipButton = card.querySelector('.teaser_logo__flip-button');
                flipButton.style.display = 'block';
            });
        });
    } else {
        const cards = document.querySelectorAll('.teaser_logo__card');
        cards.forEach(card => {
            card.addEventListener('mouseenter', () => {
                card.classList.add('is-flipped');
            });

            card.addEventListener('mouseleave', () => {
                card.classList.remove('is-flipped');
            });
        });
    }
});
