import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "team-default" }
const _hoisted_2 = { class: "team-default__inner" }
const _hoisted_3 = {
  key: 0,
  class: "team-default__title"
}
const _hoisted_4 = { class: "team-default-title__text" }
const _hoisted_5 = {
  key: 1,
  class: "team-default__headline"
}
const _hoisted_6 = {
  key: 2,
  class: "team-default__subline"
}
const _hoisted_7 = { class: "team-default__selector" }

import TeamSelector from '../../../../../molecules/team-selector/vue/team-selector.vue';


export default {
  __name: 'team-default-view',
  props: {
  title: {
    type: String,
    required: true,
    validator: (value) => value.length > 0,
  },
  headline: {
    type: String,
    required: true,
    validator: (value) => value.length > 0,
  },
  subline: {
    type: String,
    required: true,
    validator: (value) => value.length > 0,
  },
  teamSelector: {
    type: Object,
    required: true,
  },
},
  setup(__props) {

const props = __props;

return (_ctx, _cache) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (__props.title)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, _toDisplayString(__props.title), 1)
          ]))
        : _createCommentVNode("", true),
      (__props.headline)
        ? (_openBlock(), _createElementBlock("div", _hoisted_5, _toDisplayString(__props.headline), 1))
        : _createCommentVNode("", true),
      (__props.subline)
        ? (_openBlock(), _createElementBlock("div", _hoisted_6, _toDisplayString(__props.subline), 1))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_7, [
        _createVNode(TeamSelector, {
          onUpdateSelectedResort: _cache[0] || (_cache[0] = $event => (_ctx.$emit('update-selected-resort', $event))),
          onUpdateSelectedPerson: _cache[1] || (_cache[1] = $event => (_ctx.$emit('update-selected-person', $event))),
          message: __props.teamSelector.message,
          "default-option-person": __props.teamSelector.defaultOptionPerson,
          "default-option-resort": __props.teamSelector.defaultOptionResort,
          resorts: __props.teamSelector.resorts,
          persons: __props.teamSelector.persons
        }, null, 8, ["message", "default-option-person", "default-option-resort", "resorts", "persons"])
      ])
    ])
  ]))
}
}

}