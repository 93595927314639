if (typeof (document.querySelector('.table-of-content')) !== 'undefined' && document.querySelector('.table-of-content') != null) {
  document.querySelectorAll('.table-of-content').forEach((element) => {
    const contentVariable = element.dataset.content;

    if (window[contentVariable].length > 0) {
      const tableOfContent = window[contentVariable];
      const $list = element.querySelector('.table-of-content__list');
      const $select = element.querySelector('.table-of-content__select-wrapper');

      let tableOfContentItemsHTML = '';
      let tableOfContentItemsHTMLMobile = '<select class="select select--custom">';


      Object.keys(tableOfContent).forEach((key) => {
        const itemHTML = `<li class="table-of-content__list-item"><a href="${tableOfContent[key].link}">${tableOfContent[key].text}</a></li>`;
        tableOfContentItemsHTML += itemHTML;

        const itemHTMLMobile = `<option value="${tableOfContent[key].link}">${tableOfContent[key].text}</option>`;
        tableOfContentItemsHTMLMobile += itemHTMLMobile;
      });

      tableOfContentItemsHTMLMobile += '</select>';
      $select.innerHTML += tableOfContentItemsHTMLMobile;
      $list.innerHTML += tableOfContentItemsHTML;

      // scroll to element select
      $select.addEventListener('change', (event) => {
        // eslint-disable-next-line no-restricted-globals
        location.hash = event.target.value;
      });
    } else {
      // eslint-disable-next-line no-param-reassign
      element.style.display = 'none';
    }
  });
}
