import { unref as _unref, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "team"
}
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { key: 1 }

import { onMounted, ref, reactive } from 'vue';
import { customError, customLog } from '../../../../../javascripts/utils/log';

// Data
import DataLoader from './lib/data';

// Components
import TeamDefaultView from '../../views/team-default-view/vue/team-default-view.vue';
import TeamPersonView from '../../views/team-person-view/vue/team-person-view.vue';
import TeamResortView from '../../views/team-resort-view/vue/team-resort-view.vue';

// --------------- PROPS ---------------

export default {
  __name: 'team',
  props: {
  apiBaseUrl: {
    type: String,
    required: true,
  },
},
  setup(__props) {

// --------------- IMPORTS ---------------
const props = __props;

// --------------- DATA HANDLING ---------------
let dataLoader = new DataLoader(props.apiBaseUrl);

// Load static team data
const staticTeamData = ref({
  title: "",
  headline: "",
  subline: "",
  backButtonText: "",
  teamSelector: {
    message: "",
    defaultOptionResort: "",
    defaultOptionPerson: "",
    resorts: [],
    persons: []
  }
});
onMounted(async () => {
  customLog('[Team]: onMounted hook');
  try {
    staticTeamData.value = await dataLoader.getStaticTeamData();
  } catch (error) {
    customError('[Team]: Error during onMounted hook:', error);
  }
});

// --------------- SELECTION STATE ---------------
const SELECTION_TYPE = Object.freeze({
  DEFAULT: 'DEFAULT',
  RESORT: 'RESORT',
  PERSON: 'PERSON',
});
const selectionState = ref({
  selectionType: SELECTION_TYPE.DEFAULT,
  selectionId: null,
});

const selectedObject = reactive({}); // <- content of centered bubble, either resort or person
const relatedObjects = ref(null); // <- content of related bubbles, either persons or resorts

function resetSelectionState() {
  customLog('[Team]: resetting selection state');
  selectionState.value = {
    selectionType: SELECTION_TYPE.DEFAULT,
    selectionId: null,
  };
}

async function handleResortSelection(newResortId) {
  customLog('[Team]: handling resort selection', newResortId);

  customLog('[Team]: loading resort data');
  const selectedResort = await dataLoader.getResortById(newResortId);
  customLog('[Team]: loading related persons');
  const relatedPersons = await dataLoader.getRelatedPersonsByResortId(newResortId);

  Object.assign(selectedObject, selectedResort);
  relatedObjects.value = relatedPersons;

  customLog('[Team]: updating selection state');
  selectionState.value = {
    selectionType: SELECTION_TYPE.RESORT,
    selectionId: newResortId,
  };
}
async function handlePersonSelection(newPersonId) {
  customLog('[Team]: handling person selection', newPersonId);

  customLog('[Team]: loading person data');
  const selectedPerson = await dataLoader.getPersonById(newPersonId);
  customLog('[Team]: loading related resorts');
  const relatedResorts = await dataLoader.getRelatedResortsByPersonId(newPersonId);

  Object.assign(selectedObject, selectedPerson);
  relatedObjects.value = relatedResorts;

  customLog('[Team]: updating selection state');
  selectionState.value = {
    selectionType: SELECTION_TYPE.PERSON,
    selectionId: newPersonId,
  };
}

return (_ctx, _cache) => {
  return (staticTeamData.value && staticTeamData.value.title)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_Transition, { name: "team-fade" }, {
          default: _withCtx(() => [
            (selectionState.value.selectionType === _unref(SELECTION_TYPE).DEFAULT)
              ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                  _createVNode(TeamDefaultView, {
                    title: staticTeamData.value.title,
                    headline: staticTeamData.value.headline,
                    subline: staticTeamData.value.subline,
                    "team-selector": staticTeamData.value.teamSelector,
                    onUpdateSelectedResort: handleResortSelection,
                    onUpdateSelectedPerson: handlePersonSelection
                  }, null, 8, ["title", "headline", "subline", "team-selector"])
                ]))
              : _createCommentVNode("", true)
          ]),
          _: 1
        }),
        (selectionState.value.selectionType === _unref(SELECTION_TYPE).RESORT)
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
              _createVNode(TeamResortView, {
                "selected-resort": selectedObject,
                "related-persons": relatedObjects.value,
                "back-button-text": staticTeamData.value.backButtonText,
                "team-selector": staticTeamData.value.teamSelector,
                onClickedBackButton: resetSelectionState,
                onUpdateSelectedResort: handleResortSelection,
                onUpdateSelectedPerson: handlePersonSelection
              }, null, 8, ["selected-resort", "related-persons", "back-button-text", "team-selector"])
            ]))
          : (selectionState.value.selectionType === _unref(SELECTION_TYPE).PERSON)
            ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                _createVNode(TeamPersonView, {
                  "selected-person": selectedObject,
                  "related-resorts": relatedObjects.value,
                  "back-button-text": staticTeamData.value.backButtonText,
                  "team-selector": staticTeamData.value.teamSelector,
                  onClickedBackButton: resetSelectionState,
                  onUpdateSelectedResort: handleResortSelection,
                  onUpdateSelectedPerson: handlePersonSelection
                }, null, 8, ["selected-person", "related-resorts", "back-button-text", "team-selector"])
              ]))
            : _createCommentVNode("", true)
      ]))
    : _createCommentVNode("", true)
}
}

}