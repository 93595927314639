export default function overlayElements(selector) {
  return document.querySelectorAll(selector)
    .forEach(($link) => {
      const $target = $link.closest(`.${$link.getAttribute('data-overlay-target')}`);
      let down;
      let up;

      if ($target) {
        $target.addEventListener('mouseover', () => {
          $target.classList.add('active-overlay');
          $target.style.cursor = 'pointer';
        });

        $target.addEventListener('mouseout', () => {
          $target.classList.remove('active-overlay');
          $target.removeAttribute('style');
        });

        // prevent click while user-selection
        $target.addEventListener('mousedown', () => {
          down = new Date();
        });

        $target.addEventListener('mouseup', (e) => {
          up = new Date();

          if (((up - down) < 200) && (e.button !== 2)) {
            $link.click();
          }
        });

        $link.addEventListener('click', (e) => {
          // prevent double link clicks firing
          if (e.target !== $link) {
            e.preventDefault();
          }
        });
      }
    });
}

overlayElements('.js-overlay-link');
